import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import {
  getOrderStatus,
  getPaymentStatus,
  getProductOrderKind,
  getShipmentDispatchStatus,
  getShipmentStatus,
  isRepurchaseOrder,
} from '~/util/statusConverter';

import Button from '~/components/Button';

import {
  convertDate,
  toCurrencyFormat,
  formatPascalCase,
} from '~/util/Functions';
import { PARTICLES_OF_NAME } from '~/constants/Users';
import {
  ORDER_KIND_DEVOLUTION,
  ORDER_KIND_ORIGINAL,
  ORDER_ORIGIN_ORDER_LISTING,
  STATUS_CANCELED,
  STATUS_DISCARDED,
} from '~/constants/Order';
import { getOrderReverseCodeData } from '~/util/ProductOrder';

export function generateColumns() {
  const data = [
    {
      title: 'N° do Pedido',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Data do Pedido',
      dataIndex: 'orderDate',
      key: 'orderDate',
      render: text => convertDate(text),
    },
    {
      title: 'Cliente',
      dataIndex: 'buyerName',
      key: 'buyerName',
      render: (_, { buyer }) => {
        return buyer
          ? formatPascalCase(
              `${buyer?.firstName} ${buyer?.lastName}`,
              PARTICLES_OF_NAME
            )
          : '-';
      },
    },
    {
      title: 'E-mail do Cliente',
      dataIndex: 'buyerEmail',
      key: 'buyerEmail',
      render: (_, { buyer }) => {
        return buyer ? buyer?.email : '-';
      },
    },
    {
      title: 'Valor do Pedido',
      dataIndex: 'total',
      key: 'total',
      width: 150,
      render: (_, record) => toCurrencyFormat(record.total),
    },
    {
      title: 'Tipo do Pedido',
      dataIndex: 'kind',
      key: 'kind',
      width: 150,
      render: (_, record) => getProductOrderKind(record?.kind),
    },
    {
      title: 'Status do Pagamento',
      dataIndex: 'currentPaymentStatus',
      key: 'currentPaymentStatus',
      render: (_, { currentPaymentStatus }) =>
        getPaymentStatus(currentPaymentStatus),
    },
    {
      title: 'Status do Envio',
      dataIndex: 'getShipmentStatus',
      key: 'getShipmentStatus',
      render: (_, { currentShipmentStatus, kind, currentStatus }) => {
        if (
          kind === ORDER_KIND_DEVOLUTION &&
          !currentShipmentStatus &&
          [STATUS_CANCELED, STATUS_DISCARDED].includes(currentStatus)
        ) {
          return 'Cancelado';
        }

        if (!currentShipmentStatus && kind === ORDER_KIND_DEVOLUTION)
          return 'Aguardando';

        return getShipmentStatus(
          currentShipmentStatus,
          kind || ORDER_KIND_ORIGINAL
        );
      },
    },
    {
      title: 'Status do Pedido',
      dataIndex: 'currentStatus',
      key: 'currentStatus',
      render: (_, { currentStatus }) => getOrderStatus(currentStatus),
    },
    {
      title: 'Status de Expedição ',
      dataIndex: 'currentShipmentDispatchStatus',
      key: 'currentShipmentDispatchStatus',
      render: (_, { currentShipmentDispatchStatus }) =>
        getShipmentDispatchStatus(currentShipmentDispatchStatus),
    },
    {
      title: 'Transportadora',
      dataIndex: 'shipmentMethodName',
      key: 'shipmentMethodName',
      render: (_, { shippingAddress }) => {
        return shippingAddress?.shipmentMethod?.shipment_name || '-';
      },
    },
    {
      title: 'Cód. Postagem',
      dataIndex: 'reverseCode',
      key: 'reverseCode',
      render: (_, record) => {
        const { postDate, reverseCodes } = getOrderReverseCodeData(record);
        if (
          record.kind === ORDER_KIND_ORIGINAL ||
          (!reverseCodes[0]?.reverseCode && !postDate)
        ) {
          return 'Não consta';
        }

        return reverseCodes[0]?.reverseCode || `Disponível em ${postDate}`;
      },
    },
    {
      title: 'Recompra',
      dataIndex: 'repurchase',
      key: 'repurchase',
      render: (_, { repurchase }) => isRepurchaseOrder(repurchase),
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'center',
      width: 80,
      fixed: 'right',
      render: (text, record) => (
        <Tooltip placement="bottom" title="Visualizar">
          <Link
            to={`/manage/orders/products/edit/${record.id}?from=${ORDER_ORIGIN_ORDER_LISTING}`}
          >
            <Button
              data-testid="viewBtn"
              type="link"
              icon={<FileSearchOutlined />}
              size="small"
              style={{ margin: 0 }}
            />
          </Link>
        </Tooltip>
      ),
    },
  ];

  return data;
}
