import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  ArrowLeftOutlined,
  LinkOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import {
  Card,
  Row,
  Col,
  Descriptions,
  Alert,
  Tooltip,
  Typography,
  Tag,
} from 'antd';

import {
  // triggerErrorNotification,
  convertDate,
  getErrorMessage,
  toCurrencyFormat,
} from '~/util/Functions';

import LoadingBar from '~/components/LoadingBar';
import useSearchOrder from '~/hooks/orders/useSearchOrder';
import {
  getOrderStatus,
  getPaymentType,
  getProductOrderKind,
  getShipmentOrderSubType,
  isRepurchaseOrder,
} from '~/util/statusConverter';
import {
  PAYMENT_TYPE_BANKSLIP,
  PAYMENT_TYPE_CREDIT_CARD,
  PAYMENT_TYPE_BALANCE,
  PAYMENT_TYPE_PIX,
  STATUS_PAID,
} from '~/constants/Payment';
import {
  SHIPMENT_SEND,
  SHIPMENT_ON_ROUTE,
  SHIPMENT_DELIVERED,
} from '~/constants/Shipment';
import {
  ORDER_KIND_DEVOLUTION,
  ORDER_KIND_ORIGINAL,
  ORDER_ORIGIN_CLIENT_LISTING,
  ORDER_ORIGIN_ORDER_DETAILS,
} from '~/constants/Order';
import Button from '~/components/Button';
import { USER_TAB_DATA } from '~/constants/Users';
import {
  calcItemTotalAndAdjustmentForRepurchase,
  getOrderReverseCodeData,
} from '~/util/ProductOrder';
import CardItem from './CardItem';
import ExpandableRawOrder from './ExpandableRawOrder';
import OrderStatusSteps from './OrderStatusSteps';

const { Text } = Typography;

function OrderForm({ match }) {
  const { search: searchQueries } = useLocation();
  const searchParams = new URLSearchParams(searchQueries);

  const debugActive = searchParams.get('debug') === 'true';

  const returnLink = React.useMemo(() => {
    const fromValue = searchParams.get('from');
    const userId = searchParams.get('userId');

    if (fromValue === ORDER_ORIGIN_CLIENT_LISTING && userId) {
      return {
        tooltip: `Voltar para pedidos do usuário ${userId}`,
        link: `/manage/service/client/edit/${userId}?tab=order`,
      };
    }

    return {
      tooltip: 'Voltar para tela de pedidos geral',
      link: '/manage/orders/products',
    };
  }, [searchQueries]);

  const orderId = useMemo(() => {
    return match?.params?.id ? Number(match.params.id) : null;
  }, [match]);

  const [order, setOrder] = useState(null);

  const [searchOrder, { data, loading, error }] = useSearchOrder();
  const search = useCallback(searchOrder, [orderId]);

  useEffect(() => {
    if (data?.length && !order) {
      setOrder(data[0]);
    }
  }, [data]);

  const shipmentDate = useMemo(() => {
    if (order) {
      const shipmentStatus = order?.shipmentStatus?.find(status => {
        return (
          status?.kind === SHIPMENT_SEND || status?.kind === SHIPMENT_ON_ROUTE
        );
      });
      return shipmentStatus?.trackingEventDate || shipmentStatus?.date;
    }
    return null;
  }, [order]);

  const shipmentDeliveredDate = useMemo(() => {
    if (order) {
      const shipmentStatus = order?.shipmentStatus?.find(
        status => status?.kind === SHIPMENT_DELIVERED
      );
      return shipmentStatus?.trackingEventDate || shipmentStatus?.date;
    }
    return null;
  }, [order]);

  const paymentDate = useMemo(() => {
    if (order) {
      return order?.paymentStatus?.find(item => item?.kind === STATUS_PAID)
        ?.date;
    }
    return null;
  }, [order]);

  const getPaymentTypeFormatted = paymentType => {
    const isBalanceOrPix =
      (paymentType === PAYMENT_TYPE_PIX ||
        paymentType === PAYMENT_TYPE_BALANCE) &&
      order?.currentPaymentStatus === STATUS_PAID &&
      paymentDate;
    return isBalanceOrPix
      ? `Pago com ${getPaymentType(paymentType)} em ${convertDate(paymentDate)}`
      : getPaymentType(paymentType);
  };

  const getBalanceUsed = paymentType => {
    const balanceUsed =
      paymentType === PAYMENT_TYPE_BALANCE
        ? order?.orderTotal
        : order?.balanceUsed;
    return Math.abs(balanceUsed)
      ? `-${toCurrencyFormat(balanceUsed)}`
      : 'Não consta';
  };

  const discountSaleTotal = useMemo(() => {
    if (order) {
      const discountSaleValues = order?.orderItems
        ?.filter(item => !!item?.saleDiscount)
        .map(item => item?.saleDiscount);

      return discountSaleValues?.length
        ? discountSaleValues.reduce(
            (prevDiscount, currDiscount) =>
              Number(prevDiscount) + Number(currDiscount)
          )
        : null;
    }
    return null;
  }, [order]);

  const renderPostCode = () => {
    const { postDate, reverseCodes } = getOrderReverseCodeData(order);
    if (reverseCodes[0]?.reverseCode || postDate) {
      return (
        <Descriptions.Item
          label={<span data-testid="reverseCode">Código de postagem</span>}
        >
          {reverseCodes[0]?.reverseCode || `Disponível em ${postDate}`}
        </Descriptions.Item>
      );
    }

    return null;
  };

  useEffect(() => {
    if (orderId) {
      search({
        id: orderId,
        size: 1,
        skip: 0,
        full: true,
      });
    }
  }, [orderId, search]);

  if (loading) return <LoadingBar />;

  return (
    <Card
      data-testid="orderCardForm"
      title={
        <>
          <Row gutter={16} type="flex" align="middle" justify="space-between">
            <Col md={12} xs={24}>
              <Tooltip
                placement="bottom"
                title={
                  returnLink?.tooltip || 'Voltar para tela de pedidos geral'
                }
              >
                <Link to={returnLink?.link || '/manage/orders/products'}>
                  <ArrowLeftOutlined data-testid="backToListBtn" />
                </Link>
              </Tooltip>
              <span data-testid="orderTitle" style={{ marginLeft: '10px' }}>
                {`PEDIDO ${orderId} - ${getOrderStatus(order?.currentStatus)}`}
              </span>
            </Col>
            <Col md={12} xs={24}>
              <Row type="flex" justify="end" align="middle">
                <Tooltip
                  placement="bottom"
                  title={
                    order?.trackingUrl
                      ? 'Abrir rastreio em uma nova aba'
                      : 'Rastreio não está presente nesse pedido!'
                  }
                >
                  <Button
                    data-testid="goToInvoiceBtn"
                    loading={loading}
                    type={order?.trackingUrl ? 'primary' : 'danger'}
                    disabled={!order?.trackingUrl}
                    href={order?.trackingUrl}
                    target="_blank"
                    style={{
                      padding: '0px 15px',
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: 10,
                    }}
                  >
                    Rastreio do pedido
                  </Button>
                </Tooltip>

                <Tooltip
                  placement="bottom"
                  title={
                    order?.invoiceUrl
                      ? 'Abrir fatura da Iugu em uma nova aba'
                      : 'Fatura da Iugu não está presente nesse pedido!'
                  }
                >
                  <Button
                    data-testid="goToInvoiceBtn"
                    loading={loading}
                    type={order?.invoiceUrl ? 'primary' : 'danger'}
                    disabled={!order?.invoiceUrl}
                    icon={<LinkOutlined />}
                    href={order?.invoiceUrl}
                    target="_blank"
                    style={{
                      padding: '0px 15px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Fatura Iugu
                  </Button>
                </Tooltip>
              </Row>
            </Col>
            {/* <Col md={12} xs={24}>
              <Tag data-testid="orderCurrentStatus" style={{ float: 'right' }}>
                {renderCurrentStep()}
              </Tag>
            </Col> */}
          </Row>
        </>
      }
    >
      <Row gutter={[16, 16]}>
        {error && (
          <Col span={24}>
            <Alert message={getErrorMessage(error)} type="error" showIcon />
          </Col>
        )}
        {!order && (
          <Col span={24}>
            <Alert message="Pedido não encontrado!" type="warning" showIcon />
          </Col>
        )}
      </Row>
      {!error && order && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <OrderStatusSteps order={order} showDescription={debugActive} />
            </Col>
            <Col span={24} data-testid="orderDesc01">
              <Descriptions
                layout="vertical"
                bordered
                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
              >
                <Descriptions.Item
                  label={<span data-testid="orderName">Nome</span>}
                >
                  {order?.buyer?.firstName}
                  {order?.buyer?.id && (
                    <Tooltip
                      placement="bottom"
                      title="Ir para pedidos do cliente"
                    >
                      <Link
                        to={`/manage/service/client/edit/${order.buyer.id}?tab=${USER_TAB_DATA}`}
                      >
                        {' '}
                        <ExportOutlined />
                      </Link>
                    </Tooltip>
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<span data-testid="orderEmail">E-mail</span>}
                >
                  {order?.buyer?.email}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<span data-testid="orderAddress">Endereço</span>}
                >
                  {`${order?.shippingAddress?.address1},
                  ${order?.shippingAddress?.address2}
                  ${order?.shippingAddress?.number} -
                  ${order?.shippingAddress?.city}/${order?.shippingAddress?.state}`}
                </Descriptions.Item>
              </Descriptions>
            </Col>

            <Col span={24}>
              <Descriptions
                layout="vertical"
                bordered
                column={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 1, xs: 1 }}
              >
                <Descriptions.Item
                  label={<span data-testid="orderDate">Data pedido</span>}
                >
                  {convertDate(order?.orderDate)}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<span data-testid="orderId">ID do pedido</span>}
                >
                  {orderId}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<span data-testid="orderKind">Tipo do pedido</span>}
                >
                  {getProductOrderKind(order?.kind)}
                </Descriptions.Item>

                {order.kind === ORDER_KIND_DEVOLUTION &&
                order?.orderOrigin?.id ? (
                  <Descriptions.Item
                    label={
                      <span data-testid="orderKind">ID do pedido original</span>
                    }
                  >
                    <Tooltip
                      placement="bottom"
                      title="Abrir página do pedido original em uma nova aba."
                    >
                      <Link
                        to={`/manage/orders/products/edit/${order?.orderOrigin?.id}?from=${ORDER_ORIGIN_ORDER_DETAILS}`}
                        target="_blank"
                      >
                        <LinkOutlined /> {order?.orderOrigin?.id}
                      </Link>
                    </Tooltip>
                  </Descriptions.Item>
                ) : null}

                <Descriptions.Item
                  label={
                    <span data-testid="orderPaymentType">
                      Forma de Pagamento
                    </span>
                  }
                >
                  {getPaymentTypeFormatted(order?.paymentType) || 'Não consta'}
                </Descriptions.Item>

                <Descriptions.Item
                  label={<span data-testid="orderPaymentType">Recompra</span>}
                >
                  {isRepurchaseOrder(order?.repurchase)}
                </Descriptions.Item>
              </Descriptions>
            </Col>

            <Col span={24}>
              <Descriptions
                layout="vertical"
                bordered
                column={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 1, xs: 1 }}
              >
                <Descriptions.Item
                  label={<span data-testid="orderValue">Valor do pedido</span>}
                >
                  {toCurrencyFormat(order?.total)}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <span data-testid="orderShipmentValue">Valor Frete</span>
                  }
                >
                  {toCurrencyFormat(order?.finalShipmentTotal)}
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <span data-testid="orderBalanceValue">Saldo Repassa</span>
                  }
                >
                  {getBalanceUsed(order?.paymentType)}
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <span data-testid="orderDiscountCoupon">
                      Desconto Cupom
                    </span>
                  }
                >
                  {order?.couponDiscount
                    ? toCurrencyFormat(order?.couponDiscount)
                    : 'Não consta'}{' '}
                  {order?.couponCode ? (
                    <Text type="secondary">(Código: {order?.couponCode})</Text>
                  ) : null}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <span data-testid="orderDiscountSale">
                      Desconto Promoção
                    </span>
                  }
                >
                  {discountSaleTotal
                    ? `-${toCurrencyFormat(discountSaleTotal)}`
                    : 'Não consta'}
                </Descriptions.Item>
              </Descriptions>
            </Col>

            {order?.paymentType === PAYMENT_TYPE_BALANCE &&
              order?.balanceDiscount > 0 && (
                <Row gutter={[16, 16]}>
                  <Col span={24} style={{ marginLeft: '8px' }}>
                    <Descriptions
                      layout="vertical"
                      bordered
                      column={{
                        xxl: 24,
                        xl: 24,
                        lg: 24,
                        md: 24,
                        sm: 24,
                        xs: 1,
                      }}
                    >
                      <Descriptions.Item
                        label={
                          <span data-testid="orderDiscountBalance">
                            10% de desconto ao usar Saldo Repassa
                          </span>
                        }
                      >
                        {toCurrencyFormat(order?.balanceDiscount)}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              )}

            {(order?.paymentType === PAYMENT_TYPE_CREDIT_CARD ||
              order?.paymentType === PAYMENT_TYPE_BANKSLIP) && (
              <Col span={24}>
                <Descriptions
                  layout="vertical"
                  bordered
                  column={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 1 }}
                >
                  {order?.paymentType === PAYMENT_TYPE_CREDIT_CARD && (
                    <>
                      <Descriptions.Item
                        label={
                          <span data-testid="orderInstallments">
                            Número de parcelas
                          </span>
                        }
                      >
                        {order?.installments
                          ? `${order?.installments}x`
                          : 'Não consta'}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <span data-testid="orderInterestRate">
                            Juros Parcelamento
                          </span>
                        }
                      >
                        {toCurrencyFormat(order?.interestRate)}
                      </Descriptions.Item>
                    </>
                  )}
                  {order?.paymentType === PAYMENT_TYPE_BANKSLIP && (
                    <Descriptions.Item
                      label={
                        <span data-testid="orderInvoiceDueDate">
                          Data vencimento
                        </span>
                      }
                    >
                      {convertDate(order?.invoiceDueDate) || 'Não consta'}
                    </Descriptions.Item>
                  )}
                  {(order?.paymentType === PAYMENT_TYPE_CREDIT_CARD ||
                    order?.paymentType === PAYMENT_TYPE_BANKSLIP) && (
                    <Descriptions.Item
                      label={
                        <span data-testid="orderPaymentDate">
                          Data pagamento
                        </span>
                      }
                    >
                      {convertDate(paymentDate) || 'Não consta'}
                    </Descriptions.Item>
                  )}
                </Descriptions>
              </Col>
            )}

            <Col span={24}>
              <Descriptions
                layout="vertical"
                bordered
                column={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 1 }}
              >
                <Descriptions.Item
                  label={
                    <span data-testid="orderDeliveryForm">
                      {`Forma de ${
                        order?.kind === ORDER_KIND_ORIGINAL
                          ? 'entrega'
                          : 'envio'
                      }`}
                    </span>
                  }
                >
                  {order?.shippingAddress?.shipmentMethod?.shipment_name ||
                    'Não consta'}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <span data-testid="orderTrackingCode">
                      Código de rastreio
                    </span>
                  }
                >
                  {order?.trackingCode || 'Não consta'}
                  {order?.trackingCode && order?.trackingUrl && (
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={{ pathname: order.trackingUrl }}
                    >
                      {' '}
                      <ExportOutlined />
                    </Link>
                  )}
                </Descriptions.Item>
                {order?.kind === ORDER_KIND_DEVOLUTION ? (
                  <>
                    {renderPostCode()}

                    {order?.orderOrigin?.id && (
                      <Descriptions.Item
                        label={
                          <span data-testid="orderTrackingCode">
                            Método de devolução
                          </span>
                        }
                      >
                        {getShipmentOrderSubType(
                          order?.currentShipmentOrderSubType
                        )}
                      </Descriptions.Item>
                    )}
                  </>
                ) : null}
                <Descriptions.Item
                  label={<span data-testid="orderSentDate">Data envio</span>}
                >
                  {convertDate(shipmentDate) || 'Não consta'}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <span data-testid="orderReceivedDate">
                      Data Recebimento
                    </span>
                  }
                >
                  {convertDate(shipmentDeliveredDate) || 'Não consta'}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={24} />
          </Row>

          <Card
            data-testid="orderItems"
            title={
              <>
                PEÇAS <Tag>{order?.orderItems?.length}</Tag>
              </>
            }
          >
            <Row gutter={[10, 5]}>
              {order?.orderItems?.map(orderItem => {
                const { repurchaseAdjustmentTotal, repurchaseItemTotal } =
                  calcItemTotalAndAdjustmentForRepurchase(order, orderItem);

                return (
                  <Col
                    data-testid="orderCardItem"
                    xl={4}
                    lg={6}
                    md={6}
                    xs={24}
                    key={orderItem?.itemId}
                  >
                    <CardItem
                      itemId={orderItem?.itemId}
                      images={orderItem.item?.images}
                      name={orderItem?.item?.name}
                      itemValue={orderItem?.itemValue}
                      adjustmentTotal={
                        order?.repurchase
                          ? repurchaseAdjustmentTotal
                          : orderItem?.adjustmentTotal
                      }
                      itemTotal={
                        order?.repurchase
                          ? repurchaseItemTotal
                          : orderItem?.itemTotal
                      }
                      status={orderItem?.orderItemStatus?.kind}
                      barcode={orderItem?.item?.barcode}
                      reverseCode={orderItem?.reverseCode}
                      devolutionItem={orderItem?.devolutionItem}
                      slug={orderItem?.item?.slug}
                    />
                  </Col>
                );
              })}
            </Row>
          </Card>
          {debugActive ? <ExpandableRawOrder order={order} /> : null}
        </>
      )}
    </Card>
  );
}

OrderForm.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape().isRequired,
  }).isRequired,
};

export default OrderForm;
