import { ORDER_ADJUSTMENT_REPURCHASE_LABELS } from '~/constants/Order';
import { convertDate } from './Functions';

export function calcItemTotalAndAdjustmentForRepurchase(order, orderItem) {
  const qtyOrderItems = order?.orderItems?.length || 1;
  const orderItemsTotal = order?.itemTotal || 0;
  const orderItemValue = orderItem?.itemValue || 0;

  const repurchaseAdjustment = Math.abs(
    order?.orderAdjustments?.find(adjustument =>
      ORDER_ADJUSTMENT_REPURCHASE_LABELS?.includes(adjustument?.label)
    )?.value || 0
  );

  if (repurchaseAdjustment > 0) {
    const repurchaseItemTotal =
      (orderItemsTotal - repurchaseAdjustment) / qtyOrderItems;
    const repurchaseAdjustmentTotal = orderItemValue - repurchaseItemTotal;

    return {
      repurchaseItemTotal,
      repurchaseAdjustmentTotal,
    };
  }

  return {
    repurchaseItemTotal: null,
    repurchaseAdjustmentTotal: null,
  };
}

export const getOrderReverseCodeData = order => {
  const deliveredDate = new Date(order?.orderOrigin?.deliveredDate);
  const orderItemsReverseCodes = order?.orderItems?.reduce((acc, current) => {
    const currentReverseCode = current?.reverseCode;
    if (currentReverseCode && !acc.includes(currentReverseCode)) {
      acc.push({
        orderItemId: current?.id,
        reverseCode: currentReverseCode,
        reverseCodeExpiresAt: current?.reverseCodeExpiresAt,
      });
    }
    return acc;
  }, []);
  let postDate = deliveredDate.setDate(deliveredDate.getDate() + 7);
  postDate = new Date(postDate) > new Date() ? postDate : null;

  return {
    postDate: postDate ? convertDate(new Date(postDate)) : null,
    reverseCodes: orderItemsReverseCodes?.sort(
      (a, b) =>
        new Date(b.reverseCodeExpiresAt) - new Date(a.reverseCodeExpiresAt)
    ),
  };
};
