export const STATUS_PENDING = 'pending';
export const STATUS_CANCELED = 'canceled';
export const STATUS_COMPLETED = 'completed';
export const STATUS_DISCARDED = 'discarded';

export const ORDER_ITEM_STATUS_APPROVED = 'approved';
export const ORDER_ITEM_STATUS_CANCELED = 'canceled';
export const ORDER_ITEM_STATUS_COMPLETED = 'completed';
export const ORDER_ITEM_STATUS_IN_RETURN = 'in_return';
export const ORDER_ITEM_STATUS_PENDING = 'pending';
export const ORDER_ITEM_STATUS_PENDING_SHIPMENT = 'pending_shipment';
export const ORDER_ITEM_STATUS_RECEIVED = 'received';
export const ORDER_ITEM_STATUS_RETURNED = 'returned';
export const ORDER_ITEM_STATUS_SHIPPED = 'shipped';

export const ORDER_STATUSES = [
  STATUS_PENDING,
  STATUS_CANCELED,
  STATUS_COMPLETED,
];

export const ORDER_BY_CREATED_AT = 'created_at';
export const ORDER_BY_ITEM_TOTAL = 'item_total';

export const ORDER_DIRECTION_ASC = 'ASC';
export const ORDER_DIRECTION_DESC = 'DESC';

export const TRANSLATED_ORDER_ITEM_STATUS_APPROVED = 'Aprovado';
export const TRANSLATED_ORDER_ITEM_STATUS_CANCELED = 'Cancelado';
export const TRANSLATED_ORDER_ITEM_STATUS_COMPLETED = 'Finalizado';
export const TRANSLATED_ORDER_ITEM_STATUS_IN_RETURN = 'Em devolução';
export const TRANSLATED_ORDER_ITEM_STATUS_PENDING = 'Pendente';
export const TRANSLATED_ORDER_ITEM_STATUS_PENDING_SHIPMENT = 'Envio pendente';
export const TRANSLATED_ORDER_ITEM_STATUS_RECEIVED = 'Recebido';
export const TRANSLATED_ORDER_ITEM_STATUS_RETURNED = 'Devolvido';
export const TRANSLATED_ORDER_ITEM_STATUS_SHIPPED = 'Enviado';

export const ORDER_STATUS = [
  { key: 'in_return', label: TRANSLATED_ORDER_ITEM_STATUS_IN_RETURN },
  { key: 'returned', label: TRANSLATED_ORDER_ITEM_STATUS_RETURNED },
];

export const ORDER_ORIGIN_CLIENT_LISTING = 'client_listing';
export const ORDER_ORIGIN_ORDER_LISTING = 'order_listing';
export const ORDER_ORIGIN_ORDER_DETAILS = 'order_details';

export const TRANSLATED_ORDER_KIND_ORIGINAL = 'Compra';
export const TRANSLATED_ORDER_KIND_DEVOLUTION = 'Devolução';

export const ORDER_KIND_ORIGINAL = 'original';
export const ORDER_KIND_DEVOLUTION = 'devolution';

export const TRANSLATED_ORDER_SHIPMENT_SUB_TYPE_DROPOFF = 'Postagem';
export const TRANSLATED_ORDER_SHIPMENT_SUB_TYPE_PICKUP = 'Coleta';

export const ORDER_SHIPMENT_SUB_TYPE_DROPOFF = 'dropoff';
export const ORDER_SHIPMENT_SUB_TYPE_PICKUP = 'pickup';

export const ORDER_LIST_REPURCHASE = 'repurchase_orders';
export const ORDER_LIST_WITHOUT_REPURCHASE = 'without_repurchase_orders';

export const ORDER_ADJUSTMENT_REPURCHASE_LABEL_1 = 'Desconto de devolução';
export const ORDER_ADJUSTMENT_REPURCHASE_LABEL_2 = 'Compra do próprio produto';
export const ORDER_ADJUSTMENT_REPURCHASE_LABEL_3 = 'Compra do Próprio Produto';

export const ORDER_ADJUSTMENT_REPURCHASE_LABELS = [
  ORDER_ADJUSTMENT_REPURCHASE_LABEL_1,
  ORDER_ADJUSTMENT_REPURCHASE_LABEL_2,
  ORDER_ADJUSTMENT_REPURCHASE_LABEL_3,
];
